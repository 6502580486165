import { emptyAddress } from '../address/address.state';
import { Origin } from './origin.model';

export interface OriginState {
  origins: Origin[];
}

export const emptyOrigin: Origin = {
  mapId: null,
  name: '',
  airports: '',
  address: emptyAddress,
  contacts: [],
  routes: [],
};

export const initialOriginState: OriginState = {
  origins: []
};
