// import { emptyAddress } from '../address/address.state';
import { Route } from './route.model';

export interface RouteState {
  routes: Route[];
}

export const emptyRoute: Route = {
  inductionLocation: '',
  deliveryAgent: '',
  routeMapId: null,
  elementValues: [],
  schemes: [],

  //Remove
  clearanceMethodSchemeId: null,
  shipmentCreationSchemeId: null,
  hssDetailsSchemeId: null,
  trackingDetailsSchemeId: null,
  serviceOptionsSchemeId: null
};

export const initialRouteState: RouteState = {
  routes: []
};
