import { Address } from './address.model';

export interface AddressState {
  addresses: Address[];
}

export const emptyAddress: Address = {
  line1: '',
  line2: '',
  city: '',
  stateprov: '',
  postcode: '',
  country: '',
};

export const initialAddressState: AddressState = {
  addresses: []
};
